import Map from './components/Map';
import './styles.scss';

function App() {

  return (
    <div className="App">
      <Map/>
    </div>
  );
}

export default App;
